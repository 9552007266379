import React, {
  useState,
  ReactNode,
  createContext,
  FC,
  useContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  SetStateAction,
  Dispatch,
  useRef,
  useEffect,
} from 'react';
import { FormGroup, Label, Input, Button, InputGroup, InputGroupAddon, Form, CustomInput, FormProps, Col, PopoverHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isNil, noop } from 'lodash';
import * as immutable from 'object-path-immutable';
import {
  getKeyboardEventAbstract,
  isAlphanumericKey,
  KeyboardAbstract,
  keyboardAbstractToString,
  MaybePromise,
  readFileBase64Async,
  run,
  useEffectAsync,
} from './utils';
import AutoComplete, { AutoCompleteFetcher } from 'components/Autocomplete';
import ReactDatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { SelectOption } from 'components/Select';
import { FaClock, FaQuestionCircle } from 'react-icons/fa';
import DateRangePicker from 'components/DateRange';
import Translation from 'utils/Language/Translation';
import { SketchPicker } from 'react-color';
import { IoIosColorPalette } from 'react-icons/io';
import dedent from 'dedent';
import SimpleModal from 'components/SimpleModal';
import ProcessingButton from 'components/ProcessingButton';
import { useApi } from 'utils/API';
import { alert } from 'utils/Prompts';
import CDate, { DATE_TIME } from 'components/Date';
import { getOneTimeInputFromPeripheral } from 'utils/PeripheralManager';

const generateFieldId = (idPrefix: string, path: string) => `${idPrefix}-${path}`;

export const Context = createContext({
  idPrefix: '',
  disabled: false,
  setField: (path: string, value: any) => {},
  getField: (path: string, defaultValue?: any): any => {},
});

export const stringsToOptions = (v: string[]): SelectOption[] => v.map(it => ({ label: it, value: it }))
export const IntegerType = (v: any) => parseInt(v) || 0;
export const IntegerOrNullType = (v: any) => parseInt(v) || null;
export const FloatType = (v: any) => parseFloat(v) || 0;

interface LabeledProps {
  title?: ReactNode;
  children: ReactElement<{ path: string }>;
  sideBySide?: boolean;
}
export const Labeled = ({ title, children, sideBySide }: LabeledProps) => {
  const ctx = useContext(Context);
  const child = React.Children.only(children);
  const id = generateFieldId(ctx.idPrefix, child.props.path);

  if ((child.type as any).beFormUtilsIsToggle) {
    return <FormGroup>{React.cloneElement(child, { title } as any)}</FormGroup>;
  }

  if (sideBySide) {
    return (
      <FormGroup row>
        <Label for={id} sm={3}>{title}</Label>
        <Col>{children}</Col>
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      <Label for={id}>{title}</Label>
      {children}
    </FormGroup>
  );
};

export const FormTextArea: FC<{
  path: string;
  rows: number;
}> = ({ path, rows }) => {
  const [value, setValue] = useState(null);
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();
  return (
    <Input
      id={id}
      type={'textarea'}
      rows={rows}
      disabled={ctx.disabled}
      value={value === null ? storedValueAsString : value}
      onFocus={() => setValue(storedValueAsString)}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        ctx.setField(path, value);
        setValue(null);
      }}
    />
  );
};

export const FormJson: FC<{
  path: string;
  rows: number;
}> = ({ path, rows }) => {
  const [value, setValue] = useState(null);
  const [invalid, setInvalid] = useState(false);
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '{}' : JSON.stringify(storedValue, null, 2);
  return (
    <Input
      id={id}
      type={'textarea'}
      rows={rows}
      disabled={ctx.disabled}
      invalid={invalid}
      value={value === null ? storedValueAsString : value}
      onFocus={() => {
        if (isNil(value)) {
          setValue(storedValueAsString)
        }
        setInvalid(false)
      }}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        try {
          ctx.setField(path, JSON.parse(value));
          setValue(null);
        } catch (e) {
          setInvalid(true)
        }
      }}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.preventDefault();
          const self = e.target as HTMLTextAreaElement
          var start = self.selectionStart;
          var end = self.selectionEnd;
      
          // set textarea value to: text before caret + tab + text after caret
          self.value = self.value.substring(0, start) +
            "  " + self.value.substring(end);
      
          // put caret at right position again
          self.selectionStart = self.selectionEnd = start + 2;
        }
      }}
    />
  );
};

export const FormInput: FC<{
  path: string;
  typed?: (v: any) => any;
  valid?: (v: any) => any;
  hideInput?: boolean;
  disabled?: boolean;
  suggestions?: string[];
}> = ({ path, typed = (v) => v, valid, disabled, hideInput, suggestions }) => {
  const [value, setValue] = useState(null);
  const ctx = useContext(Context);
  const inputElement = useRef<HTMLInputElement>(null)

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();
  
  let list = null
  let listId = null
  if (suggestions) {
    listId = id + "-list"
    list = (
      <datalist id={listId}>
        {suggestions.map(s => <option key={s} value={s}/>)}
      </datalist>  
    )
  }

  return (
    <>
      <Input
        id={id}
        innerRef={inputElement}
        disabled={ctx.disabled || disabled}
        type={hideInput ? 'password' : 'text'}
        value={value === null ? storedValueAsString : value}
        onFocus={() => setValue(storedValueAsString)}
        onChange={(e) => setValue(e.target.value)}
        invalid={valid && !valid(storedValue)}
        onBlur={(e) => {
          ctx.setField(path, typed(value));
          setValue(null);
        }}
        list={listId}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && !e.repeat) {
            const inputValue = inputElement.current.value;
            run(async () => {
              if (inputValue.startsWith('_be_peripheral_input_')) {
                try {
                  const config = JSON.parse(inputValue.substring('_be_peripheral_input_'.length))
                  const v = await getOneTimeInputFromPeripheral(
                    config.usage,
                    config.driverName,
                    config.deviceName,
                    config.settings
                  )
                  inputElement.current.value = v
                } catch (e) {
                  inputElement.current.value = ''
                }
                return;
              }
            })
          }
        }}
      />
      {list}
    </>
  );
};

export const FormInputColor: FC<{
  path: string;
}> = ({ path }) => {
  const [value, setValue] = useState(null);
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path).replaceAll(/[{}.]/gm, "_");
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();

  return (
    <InputGroup>
      <Input
        id={id}
        type='text'
        value={value === null ? storedValueAsString : value}
        onFocus={() => setValue(storedValueAsString)}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          ctx.setField(path, value);
          setValue(null);
        }}
      />
      <InputGroupAddon addonType="append">
        <Button id={id+"_color"} color="secondary" style={{backgroundColor: storedValueAsString}}>
          <IoIosColorPalette />
        </Button>
        <UncontrolledPopover placement="bottom" target={id+"_color"} trigger="legacy">
          <PopoverHeader>
            <Translation name="T.misc.pickColor" />
          </PopoverHeader>
          <PopoverBody>
            <SketchPicker color={storedValueAsString} onChange={(c) => ctx.setField(path, c.hex)} />
          </PopoverBody>
        </UncontrolledPopover>
      </InputGroupAddon>
    </InputGroup>
  )
};

export const FormCron: FC<{
  path: string;
}> = ({ path }) => {
  const [value, setValue] = useState(null);
  const ctx = useContext(Context);
  const api = useApi()

  const id = generateFieldId(ctx.idPrefix, path).replaceAll(/[{}.]/gm, "_");
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();

  return (
    <InputGroup>
      <Input
        id={id}
        type='text'
        value={value === null ? storedValueAsString : value}
        onFocus={() => setValue(storedValueAsString)}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          ctx.setField(path, value);
          setValue(null);
        }}
      />
      <InputGroupAddon addonType="append">
        <ProcessingButton onClick={async () => {
          const next = await api.system.testCron(value === null ? storedValueAsString : value)
          if (!next.next) {
            await alert(<Translation name="T.misc.cronTestResultNoNext" />)
          } else {
            await alert(
              <>
                <Translation name="T.misc.cronTestResult" params={{ next: <CDate val={next.next} format={DATE_TIME} />}}/>
                {next.nextXMoments.length > 0 && (
                  <ol>
                    {next.nextXMoments.map((it, idx) => <li key={idx}><CDate val={it} format={DATE_TIME} /></li>)}
                  </ol>
                )}
              </>
            )
          }
        }}>
          <Translation name="T.misc.test" />
        </ProcessingButton>
        <SimpleModal
          fullscreen
          title={<Translation name="T.misc.cronExplanationTab" />}
          trigger={open => (
            <Button onClick={open} color="info">
              <FaQuestionCircle />
            </Button>  
          )}
        >
          <div dangerouslySetInnerHTML={{ __html: dedent`
            <div class="block">Parse the given
            <a target="_blank" href="https://www.manpagez.com/man/5/crontab/">crontab expression</a>
            string into a <code>CronExpression</code>.
            The string has six single space-separated time and date fields:
            <pre>┌───────────── second (0-59)
            │ ┌───────────── minute (0 - 59)
            │ │ ┌───────────── hour (0 - 23)
            │ │ │ ┌───────────── day of the month (1 - 31)
            │ │ │ │ ┌───────────── month (1 - 12) (or JAN-DEC)
            │ │ │ │ │ ┌───────────── day of the week (0 - 7) (0 or 7 is Sunday, or MON-SUN)
            │ │ │ │ │ │ 
            │ │ │ │ │ │
            * * * * * *
            </pre>
          
            <p>The following rules apply:
            </p><ul>
            <li>
            A field may be an asterisk (<code>*</code>), which always stands for
            "first-last". For the "day of the month" or "day of the week" fields, a
            question mark (<code>?</code>) may be used instead of an asterisk.
            </li>
            <li>
            Ranges of numbers are expressed by two numbers separated with a hyphen
            (<code>-</code>). The specified range is inclusive.
            </li>
            <li>Following a range (or <code>*</code>) with <code>/n</code> specifies
            the interval of the number's value through the range.
            </li>
            <li>
            English names can also be used for the "month" and "day of week" fields.
            Use the first three letters of the particular day or month (case does not
            matter).
            </li>
            <li>
            The "day of month" and "day of week" fields can contain a
            <code>L</code>-character, which stands for "last", and has a different meaning
            in each field:
            <ul>
            <li>
            In the "day of month" field, <code>L</code> stands for "the last day of the
            month". If followed by an negative offset (i.e. <code>L-n</code>), it means
            "<code>n</code>th-to-last day of the month". If followed by <code>W</code> (i.e.
            <code>LW</code>), it means "the last weekday of the month".
            </li>
            <li>
            In the "day of week" field, <code>dL</code> or <code>DDDL</code> stands for
            "the last day of week <code>d</code> (or <code>DDD</code>) in the month".
            </li>
            </ul>
            </li>
            <li>
            The "day of month" field can be <code>nW</code>, which stands for "the nearest
            weekday to day of the month <code>n</code>".
            If <code>n</code> falls on Saturday, this yields the Friday before it.
            If <code>n</code> falls on Sunday, this yields the Monday after,
            which also happens if <code>n</code> is <code>1</code> and falls on a Saturday
            (i.e. <code>1W</code> stands for "the first weekday of the month").
            </li>
            <li>
            The "day of week" field can be <code>d#n</code> (or <code>DDD#n</code>), which
            stands for "the <code>n</code>-th day of week <code>d</code> (or <code>DDD</code>) in
            the month".
            </li>
            </ul>
          
            <p>Example expressions:
            </p><ul>
            <li><code>"0 0 * * * *"</code> = the top of every hour of every day.</li>
            <li><code>"*/10 * * * * *"</code> = every ten seconds.</li>
            <li><code>"0 0 8-10 * * *"</code> = 8, 9 and 10 o'clock of every day.</li>
            <li><code>"0 0 6,19 * * *"</code> = 6:00 AM and 7:00 PM every day.</li>
            <li><code>"0 0/30 8-10 * * *"</code> = 8:00, 8:30, 9:00, 9:30, 10:00 and 10:30 every day.</li>
            <li><code>"0 0 9-17 * * MON-FRI"</code> = on the hour nine-to-five weekdays</li>
            <li><code>"0 0 0 25 12 ?"</code> = every Christmas Day at midnight</li>
            <li><code>"0 0 0 L * *"</code> = last day of the month at midnight</li>
            <li><code>"0 0 0 L-3 * *"</code> = third-to-last day of the month at midnight</li>
            <li><code>"0 0 0 1W * *"</code> = first weekday of the month at midnight</li>
            <li><code>"0 0 0 LW * *"</code> = last weekday of the month at midnight</li>
            <li><code>"0 0 0 * * 5L"</code> = last Friday of the month at midnight</li>
            <li><code>"0 0 0 * * THUL"</code> = last Thursday of the month at midnight</li>
            <li><code>"0 0 0 ? * 5#2"</code> = the second Friday in the month at midnight</li>
            <li><code>"0 0 0 ? * MON#1"</code> = the first Monday in the month at midnight</li>
            </ul>
          
            <p>The following macros are also supported:
            </p><ul>
            <li><code>"@yearly"</code> (or <code>"@annually"</code>) to run un once a year, i.e. <code>"0 0 0 1 1 *"</code>,</li>
            <li><code>"@monthly"</code> to run once a month, i.e. <code>"0 0 0 1 * *"</code>,</li>
            <li><code>"@weekly"</code> to run once a week, i.e. <code>"0 0 0 * * 0"</code>,</li>
            <li><code>"@daily"</code> (or <code>"@midnight"</code>) to run once a day, i.e. <code>"0 0 0 * * *"</code>,</li>
            <li><code>"@hourly"</code> to run once an hour, i.e. <code>"0 0 * * * *"</code>.</li>
            </ul></div>
          ` }}/>
        </SimpleModal>
      </InputGroupAddon>
    </InputGroup>
  )
};

export const FormInputButton: FC<{
  path: string;
  typed?: (v: any) => any;
  valid?: (v: any) => any;
  hideInput?: boolean;
  disabled?: boolean;
}> = ({ path, typed = (v) => v, valid, disabled, hideInput, children }) => {
  const [value, setValue] = useState(null);
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();
  return (
    <InputGroup>
      <Input
        id={id}
        disabled={ctx.disabled || disabled}
        type={hideInput ? 'password' : 'text'}
        value={value === null ? storedValueAsString : value}
        onFocus={() => setValue(storedValueAsString)}
        onChange={(e) => setValue(e.target.value)}
        invalid={valid && !valid(storedValue)}
        onBlur={(e) => {
          ctx.setField(path, typed(value));
          setValue(null);
        }}
      />
      <InputGroupAddon addonType="append">
        {children}
      </InputGroupAddon>
    </InputGroup>
  );
};

export const FormToggle: FC<{ path: string; title?: ReactNode }> = ({ path, title }) => {
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const value = ctx.getField(path);
  return (
    <CustomInput
      id={id}
      disabled={ctx.disabled}
      type={'switch'}
      checked={isNil(value) ? false : value}
      onChange={(e) => ctx.setField(path, e.target.checked)}
      label={title}
    />
  );
};
(FormToggle as any).beFormUtilsIsToggle = true;

export const FormCheckbox: FC<{ path: string; title?: ReactNode }> = ({ path, title }) => {
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const value = ctx.getField(path);
  
  const inner = <Input
    id={id}
    disabled={ctx.disabled}
    type={'checkbox'}
    checked={isNil(value) ? false : value}
    onChange={(e) => ctx.setField(path, e.target.checked)}
  />
  
  if (title) {
    return (
      <FormGroup check>
        <Label check>
          {inner}{' '}
          {title}
        </Label>
      </FormGroup>
    );
  } else {
    return inner;
  }
};
(FormCheckbox as any).beFormUtilsIsToggle = true;


export const FormRange: FC<{ path: string; typed?: (v: any) => any; min: number; max: number; step?: number }> = ({
  path,
  typed = (v) => v,
  min,
  max,
  step,
}) => {
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const value = ctx.getField(path);
  return (
    <CustomInput
      id={id}
      type="range"
      disabled={ctx.disabled}
      min={min}
      max={max}
      step={step}
      value={isNil(value) ? 0 : value}
      onChange={(e) => ctx.setField(path, typed(e.target.value))}
    />
  );
};

export const FormShortcutKeySelector: FC<{
  path: string;
}> = ({ path }) => {
  const [curr, setCurr] = useState<KeyboardAbstract>(null);
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const value = ctx.getField(path, {});

  const updateEvent = (e: React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const newAbstract = getKeyboardEventAbstract(e);
    setCurr(newAbstract);
    if (isAlphanumericKey(e)) {
      ctx.setField(path, newAbstract);
      (e.target as any)?.blur?.();
    }
  };

  return (
    <Input
      id={id}
      type={'text'}
      disabled={ctx.disabled}
      value={keyboardAbstractToString(curr ? curr : value)}
      onKeyDown={updateEvent}
      onKeyUp={updateEvent}
      onBlur={(e) => setCurr(null)}
      onChange={noop}
    />
  );
};

export const FormSelect: FC<{
  path: string;
  valid?: (v: any) => any;
  options: SelectOption[];
  includeEmptyOption?: boolean;
}> = ({ path, valid, options: _options, includeEmptyOption }) => {
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  let options = _options
  if (includeEmptyOption) {
    options = [{ label: '', value: null }, ...options]
  }
  return (
    <Input
      id={id}
      disabled={ctx.disabled}
      value={options.findIndex((it) => it.value === storedValue)}
      onChange={(e) => ctx.setField(path, options[parseInt(e.target.value) || 0]?.value)}
      invalid={valid && !valid(storedValue)}
      type="select"
    >
      {options.map(
        (it, index) =>
          it && (
            <option key={index} value={index}>
              {it.label}
            </option>
          ),
      )}
    </Input>
  );
};

export const FormSelectButton: FC<{
  path: string;
  valid?: (v: any) => any;
  options: SelectOption[];
}> = ({ path, valid, options, children }) => {
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  return (
    <InputGroup>
      <Input
        id={id}
        disabled={ctx.disabled}
        value={options.findIndex((it) => it.value === storedValue)}
        onChange={(e) => ctx.setField(path, options[parseInt(e.target.value) || 0]?.value)}
        invalid={valid && !valid(storedValue)}
        type="select"
      >
        {options.map(
          (it, index) =>
            it && (
              <option key={index} value={index}>
                {it.label}
              </option>
            ),
        )}
      </Input>
      <InputGroupAddon addonType="append">{children}</InputGroupAddon>
    </InputGroup>
  );
};

export function FormObject<T>({
  path,
  objectPath,
  disabled,
  find,
  getCode,
  render,
  autocomplete,
  getEditValue,
}: PropsWithChildren<{
  path: string;
  objectPath?: string;
  disabled?: boolean;
  autocomplete?: AutoCompleteFetcher;
  find: (v: string) => MaybePromise<T>;
  getCode: (v: T) => string;
  getEditValue?: (v: T) => string;
  render: (v: T) => ReactElement;
}>) {
  const [value, setValue] = useState(null);
  const [object, setObject] = useState(null);
  const [invalid, setInvalid] = useState(false);
  const ctx = useContext(Context);
  const dataRef = useRef<any>({});
  const setField = ctx.setField

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();

  useEffectAsync(async () => {
    if (storedValueAsString) {
      let result = null;
      try {
        result = await find(storedValueAsString);
      } finally {
        if (!result) {
          setObject(null);
          setInvalid(true);
          setValue(storedValueAsString);
        } else {
          const code = getCode(result);
          if (storedValueAsString !== code) {
            ctx.setField(path, code);
          }
          setObject(result);
          setValue(null);
          setInvalid(false);
        }
      }
    } else {
      setObject(null);
      setInvalid(false);
      setValue(storedValueAsString);
    }
  }, [ctx.setField, storedValueAsString, find, getCode]);

  useEffect(() => {
    if (objectPath) {
      setField(objectPath, object)
    }
  }, [setField, objectPath, object])

  const tryToCommit = async (v: string) => {
    ctx.setField(path, v);
    setValue(null);
  };

  return (
    <>
      {value === null && !isNil(object) ? (
        <InputGroup>
          <div
            tabIndex={-1}
            ref={(field) => {
              if (field && dataRef.current.shouldFocus) {
                field.focus();
                dataRef.current.shouldFocus = false;
              }
            }}
            className="form-control overflow-auto"
          >
            {render(object)}
          </div>
          <InputGroupAddon addonType="append">
            <Button 
              disabled={disabled}
              color="secondary" 
              onClick={async () => ctx.setField(path, null)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button
              disabled={disabled}
              data-prevent-auto-focus="true"
              color="primary"
              onClick={async () => {
                dataRef.current.shouldFocus = true;
                setValue(getEditValue?.(object) || storedValueAsString);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      ) : (
        <AutoComplete
          fetchAutocompleteOptions={autocomplete}
          onSelect={(s) => {
            tryToCommit(s);
            dataRef.current.shouldFocus = true;
          }}
          onBlur={(s) => tryToCommit(s)}
        >
          {({ ref, onBlur, onKeyUpHandler, onKeyDownHandler }) => (
            <Input
              innerRef={(inner) => {
                if (inner && dataRef.current.shouldFocus) {
                  inner.focus();
                  dataRef.current.shouldFocus = false;
                }
                ref.current = inner;
              }}
              disabled={ctx.disabled}
              autoComplete="off"
              onKeyDown={onKeyDownHandler}
              onKeyUp={onKeyUpHandler}
              onBlur={onBlur}
              onChange={(e) => setValue(e.target.value)}
              value={value === null ? storedValueAsString : value}
              id={id}
              invalid={invalid}
            />
          )}
        </AutoComplete>
      )}
    </>
  );
}

export const FormFile: FC<{
  path: string;
  namePath?: string;
}> = ({ path, namePath }) => {
  const ctx = useContext(Context);
  const id = generateFieldId(ctx.idPrefix, path);
  return (
    <CustomInput
      id={id}
      type={'file'}
      disabled={ctx.disabled}
      onChange={(e) => {
        const files = e.target.files
        if (files.length === 0) {
          return;
        }

        const file = files[0]
        run(async () => {
          const base64 = await readFileBase64Async(file)
          if (namePath) {
            ctx.setField(namePath, file.name)
          }
          ctx.setField(path, base64);
        })
      }}
    />
  );
}

export const FormDate: FC<{
  path: string;
}> = ({ path }) => {
  const ctx = useContext(Context);
  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  return (
    <ReactDatePicker
      id={id}
      wrapperClassName="w-100"
      dateFormat="dd/MM/yyyy"
      customInput={
        <MaskedInput
          data-testid="date"
          mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          keepCharPositions
          guide
          render={(ref, props) => {
            return (
              <InputGroup>
                <Input innerRef={ref} {...props}/>
                <InputGroupAddon addonType='append'>
                  <Button onClick={() => ctx.setField(path, new Date())}>
                    <FaClock/>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            )
          }}
        />
      }
      selected={storedValue}
      onChange={(date) => ctx.setField(path, date)}
      className="form-control"
    />
  );
};

export const FormDateRange: FC<{
  path: string;
  pathEnd: string;
}> = ({ path, pathEnd }) => {
  const ctx = useContext(Context);
  const id = generateFieldId(ctx.idPrefix, path);
  return (
    <DateRangePicker
      id={id}
      startDate={ctx.getField(path)}
      endDate={ctx.getField(pathEnd)}
      setStartDate={(date) => ctx.setField(path, date)}
      setEndDate={(date) => ctx.setField(pathEnd, date)}
    />
  );
};

export const FormDateTime: FC<{
  path: string;
}> = ({ path }) => {
  const ctx = useContext(Context);
  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  return (
    <ReactDatePicker
      id={id}
      wrapperClassName="w-100"
      dateFormat="dd/MM/yyyy HH:mm:ss"
      showTimeInput
      customInput={
        <MaskedInput
          data-testid="datetime"
          mask={[
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            '/',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/,
            ':',
            /\d/,
            /\d/,
          ]}
          keepCharPositions
          guide
          render={(ref, props) => {
            return (
              <InputGroup>
                <Input innerRef={ref} {...props}/>
                <InputGroupAddon addonType='append'>
                  <Button onClick={() => ctx.setField(path, new Date())}>
                    <FaClock/>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            )
          }}
        />
      }
      selected={storedValue}
      onChange={(date: Date) => ctx.setField(path, date)}
      className="form-control"
    />
  );
};

export const FormAutoComplete: FC<{
  path: string;
  autocomplete: AutoCompleteFetcher;
  typed?: (v: any) => any;
}> = ({ path, autocomplete, typed = (v) => v }) => {
  const [value, setValue] = useState(null);
  const ctx = useContext(Context);

  const id = generateFieldId(ctx.idPrefix, path);
  const storedValue = ctx.getField(path);
  const storedValueAsString = isNil(storedValue) ? '' : storedValue.toString();

  const commit = (value: string) => {
    ctx.setField(path, typed(value));
    setValue(null);
  };

  return (
    <AutoComplete fetchAutocompleteOptions={autocomplete} onSelect={(s) => commit(s)} onBlur={(s) => commit(s)}>
      {({ ref, onBlur, onKeyDownHandler, onKeyUpHandler }) => (
        <Input
          innerRef={ref}
          onKeyDown={onKeyDownHandler}
          onKeyUp={onKeyUpHandler}
          onBlur={onBlur}
          id={id}
          value={value === null ? storedValueAsString : value}
          onFocus={() => setValue(storedValueAsString)}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </AutoComplete>
  );
};

interface Props<T> {
  idPrefix?: string;
  disabled?: boolean;
  state: T;
  setState: Dispatch<SetStateAction<T>>;
  onStateChange?: (state: T) => any;
  containerProps?: FormProps;
  disableFormWrapper?: boolean;
}

export function FormState<T>({
  state,
  setState,
  onStateChange,
  children,
  idPrefix,
  disabled,
  containerProps = {},
  disableFormWrapper = false
}: PropsWithChildren<Props<T>>): ReactElement {
  const setField = useCallback(
    (path: string, value: any) => {
      setState((old: any) => {
        const oldValue = immutable.get(old, path);
        if (oldValue === value) {
          return old;
        }

        const newState = immutable.set(old, path, value);
        setTimeout(() => { onStateChange?.(newState); }, 0);
        return newState;
      });
    },
    [setState, onStateChange],
  );

  const inner = (
    <Context.Provider
      value={{
        idPrefix,
        disabled,
        setField,
        getField: (path: string, defaultValue: any) => immutable.get(state, path, defaultValue),
      }}
    >
      {children}
    </Context.Provider>
  )

  if (disableFormWrapper) {
    return inner;  
  }
  return (
    <Form
      {...containerProps as any}
      onSubmit={(e) => {
        e.preventDefault();
        (document.activeElement as HTMLElement)?.blur?.();
      }}
    >{inner}</Form>
  );
}
