import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import SimpleModal, { ModalRef } from 'components/SimpleModal';
import Translation from 'utils/Language/Translation';
import ProcessingButton from 'components/ProcessingButton';
import classNames from 'classnames';
import YesNoButtons from 'components/YesNoButtons';
import { IAttachment } from 'interfaces';
import { Button, ButtonGroup, Collapse, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { FaCamera, FaExternalLinkAlt, FaFileDownload, FaInfoCircle, FaPlus, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { readFileBase64Async, useBreakpointWatcher } from 'component_utils/utils';
import { alert, confirm, prompt, promptAutocomplete } from 'utils/Prompts';
import moment from 'moment';
import FileSaver from 'file-saver';
import { useApi } from 'utils/API';
import { addNotification } from 'utils/NotificationManager';
import { IoIosAlbums } from 'react-icons/io';
import { LuFileStack } from "react-icons/lu";
import './style.scss'
import { IoCloseCircleOutline } from 'react-icons/io5';

interface Props {
  onAddFiles: (files: { blob: string, name: string, extension: string }[]) => any;
  onRemoveFile?: (index: number) => any;
  files?: IAttachment[];
  closeAfterOnPicture?: boolean;
  hideCurrentFilesOverview?: boolean;
  onOpen?: () => any;

  groupControls?: {
    options: string[];
    currentGroup: string;
    setGroup: (s: string) => any;
    deleteGroup: () => any;
  }
}

export interface PictureBoxRef {
  open: () => void;
}

export function attachmentToImage(attachment: IAttachment): string {
  if (['png', 'jpg', 'jpeg', 'gif'].includes(attachment.extension.toLowerCase())) {
    return `data:image/${attachment.extension};base64,${attachment.blob}`;
  }
  if (['svg'].includes(attachment.extension.toLowerCase())) {
    return `data:image/svg+xml;base64,${attachment.blob}`;
  }
  return 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"/></svg>'
}

const PictureBox = React.forwardRef<PictureBoxRef, Props>(
  ({ onAddFiles, onRemoveFile, onOpen, files, closeAfterOnPicture, hideCurrentFilesOverview, groupControls }, ref) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const cameraInputRef = useRef<HTMLInputElement>(null);
    const modalRef = useRef<ModalRef>();
    const api = useApi()
    const [state, setState] = useState<'not playing'|'playing'|'unplayable'>('not playing');
    const [image, setImage] = useState<any>();
    const [sizes, setSizes] = useState({ width: 320, height: 240 });
    const breakpoint = useBreakpointWatcher()
    const mobileView = ["xs"].includes(breakpoint)
    const [currentFilesCollapsed, setCurrentFilesCollapsed] = useState(true)

    useEffect(() => {
      if (!mobileView) {
        setCurrentFilesCollapsed(false)
      } else {
        setCurrentFilesCollapsed(true)
      }
    }, [mobileView])

    useImperativeHandle(
      ref,
      () => ({
        open: () => {
          onOpen?.()
          modalRef.current.toggleOpen()
        },
      }),
      [onOpen],
    );

    const noVideoFeed = !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia;

    const startCamera = async () => {
      if (noVideoFeed) {
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          video: {
            facingMode: 'environment',
            width: { ideal: 1280 },
            height: { ideal: 720 } 
          },
          audio: false
        });
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        videoRef.current.addEventListener(
          'canplay',
          () => {
            const maxWidth = Math.min(400, window.innerWidth - 60);
            const maxHeight = Math.min(400, window.innerHeight - 390)

            const minRatio = Math.min(
              maxHeight / videoRef.current.videoHeight,
              maxWidth / videoRef.current.videoWidth
            )

            setSizes({
              width: videoRef.current.videoWidth * minRatio,
              height: videoRef.current.videoHeight * minRatio,
            });
            setState('playing');
          },
          { once: true },
        );
      } catch (e) {
        console.error(e);
        setState('unplayable');
      }
    };

    const stopCamera = async () => {
      if (noVideoFeed) {
        return;
      }

      setState('not playing');
      setImage(null);
      if (state === 'playing') {
        (videoRef.current.srcObject as MediaStream).getTracks().forEach(function (track) {
          track.stop();
        });
      }
    };

    useEffect(() => {
      return () => {
        stopCamera();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onFilesDropped = async (fileList: FileList) => {
      const files = Array.from(fileList)
      await onAddFiles(await Promise.all(files.map(async file => {
        const base64 = await readFileBase64Async(file)
        const extension = file.name.split('.').pop().toLowerCase();
        return {
          blob: base64, 
          name: file.name, 
          extension
        }
      })))
    }

    const imageList = (
      <Collapse isOpen={!currentFilesCollapsed}>
        <div
          className="d-flex flex-row my-2 pt-3"
          style={{
            width: '100%',
            overflowX: 'scroll',
            height: '150px',
          }}
        >
          {files?.map((it, index) => (
            <div className="position-relative flex-shrink-0 mr-3" key={index}>
              <img alt="attachment" style={{ height: '100%' }} src={attachmentToImage(it)} />
              {it.extension.toLowerCase() === 'pdf' && (
                <div className='position-absolute' style={{left: '50%', bottom: '10px', transform: 'translate(-50%, 0%)'}}>
                  <Button
                    className='p-0'
                    color="link"
                    style={{ zIndex: 1 }}
                    onClick={() => {
                      const data = Uint8Array.from(
                        atob(it.blob as any).split('').map(char => char.charCodeAt(0))
                      );  
                      const file = new Blob([data], { type: 'application/pdf' });
                      const fileURL = URL.createObjectURL(file);
                      window.open(fileURL, '_blank');
                    }}
                  >
                    <FaExternalLinkAlt color="orange" />
                  </Button>
                </div>
              )}
              <div className="position-absolute top-0 end-0 d-flex flex-row" style={{transform: 'translate(0.75em, -50%)'}}>
                <Button
                  className='p-0'
                  color="link"
                  style={{ zIndex: 1 }}
                  onClick={async () => {
                    const data = Uint8Array.from(
                      atob(it.blob as any).split('').map(char => char.charCodeAt(0))
                    );  
                    const file = new Blob([data]);
                    FileSaver.saveAs(file, it.name);
                  }}
                >
                  <FaFileDownload color='green' />
                </Button>
                {' '}
                <Button
                  className='p-0'
                  color="link"
                  style={{ zIndex: 1 }}
                  onClick={() => {
                    alert(<Translation name="T.misc.attachmentInfo" params={{name: it.name}}/>)
                  }}
                >
                  <FaInfoCircle color='blue' />
                </Button>
                {' '}
                <Button
                  className='p-0'
                  color="link"
                  style={{ zIndex: 1 }}
                  onClick={() => onRemoveFile?.(index)}
                >
                  <FaTrash color="red" />
                </Button>
              </div>
            </div>
          ))}

          <div 
            className="position-relative flex-shrink-0 h-100 p-relative" 
            role="button"
            style={{ 
              width: '100px', 
              background: 'gray',
            }}
            onClick={() => fileInputRef.current.click()}
            onDragEnter={(e) => {
              e.stopPropagation();
              e.preventDefault();        
              (e.target as HTMLDivElement).classList.add('bg-tertiary')
            }}
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();        
              (e.target as HTMLDivElement).classList.add('bg-tertiary')
            }}
            onDragLeave={(e) => {
              e.stopPropagation();
              e.preventDefault();        
              (e.target as HTMLDivElement).classList.remove('bg-tertiary')
            }}
            onDrop={(e) => {
              e.stopPropagation();
              e.preventDefault();        
              (e.target as HTMLDivElement).classList.remove('bg-tertiary')
              const dt = e.dataTransfer
              const files = dt.files
              onFilesDropped(files)
            }}
          >
            <input type='file' className='d-none' ref={fileInputRef} onChange={e => {
              const target = e.target;
              (async () => {
                await onFilesDropped(target.files)
                target.value = null  
              })();
            }}/>
            <FaPlusCircle
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none'
              }}
            />
          </div>
        </div>
      </Collapse>
    );

    return (
      <SimpleModal
        title={<Translation name="T.misc.takePicture" />}
        ref={modalRef}
        onOpened={startCamera}
        onClose={stopCamera}
        disableCloseTriggers={!noVideoFeed && state === 'not playing'}
      >
        {(close) => (
          <div className={mobileView && 'picture-box-mobile'}>
            {groupControls && (
              <>
                {' '}
                <InputGroup className='mb-2'>
                  <Input type='select' value={groupControls.currentGroup} onChange={e => groupControls.setGroup(e.target.value)}>
                    {groupControls.options.map(it => (
                      <option key={it} value={it}>{it}</option>
                    ))}
                  </Input>
                  <InputGroupAddon addonType="append">
                    <ProcessingButton onClick={async () => {
                      const newGroup = await prompt(<Translation name="T.pictureBox.newGroup"/>, '')
                      if (newGroup) {
                        groupControls.setGroup(newGroup)
                      }
                    }}>
                      <FaPlus/>
                    </ProcessingButton>
                    <ProcessingButton color='danger' onClick={async () => {
                      if (await confirm(<Translation name="T.pictureBox.areYouSureYouWantToDeleteThisGroup"/>))
                        groupControls.deleteGroup()
                    }}>
                      <FaTrash/>
                    </ProcessingButton>
                  </InputGroupAddon>
                </InputGroup>
              </>
            )}

            {noVideoFeed ? (
              <div className={classNames('text-center')}>
                <Translation name="T.errors.videoNotAvailable" />
              </div>
            ) : (
              <>
                <div className={classNames('picture-box-inner-view', 'text-center', image && 'd-none')}>
                  <div className='picture-box-image'>
                    <video autoPlay={true} ref={videoRef} {...sizes} />
                  </div>
                  <canvas ref={canvasRef} className="d-none" />
                  <input type='file' className="d-none" capture='environment' accept="image/*" ref={cameraInputRef} onChange={e => {
                    const target = e.target;
                    (async () => {
                      await onFilesDropped(target.files)
                      target.value = null
                      if (closeAfterOnPicture) {
                        close();
                      }
                    })();
                  }}/>
                  {!hideCurrentFilesOverview && imageList}
                  <ButtonGroup className='w-100'>
                    <ProcessingButton
                      block
                      color="primary"
                      disabled={state !== 'playing'}
                      onClick={async () => {
                        canvasRef.current.width = videoRef.current.videoWidth
                        canvasRef.current.height = videoRef.current.videoHeight
                        canvasRef.current.getContext('2d').drawImage(
                          videoRef.current, 
                          0, 
                          0, 
                          videoRef.current.videoWidth, 
                          videoRef.current.videoHeight
                        );
                        setImage(canvasRef.current.toDataURL('image/png'));
                      }}
                    >
                      <Translation name="T.misc.takePicture" />
                    </ProcessingButton>
                    {mobileView && (
                      <ProcessingButton color='secondary' onClick={() => {
                        setCurrentFilesCollapsed(old => !old)
                      }}>
                        <LuFileStack/>
                      </ProcessingButton>
                    )}
                    <ProcessingButton color='tertiary' onClick={() => {
                      cameraInputRef.current.click()
                    }}>
                      <FaCamera/>
                    </ProcessingButton>
                    <ProcessingButton color="info" onClick={async () => {
                      const groups = (await api.fileManagement.getContents({ path: ['temp', 'albums'] }, false))
                        .filter(it => it.isDirectory && !it.isHidden)
                      if (groups.length === 0) {
                        throw addNotification('danger', <Translation name='T.errors.pictureBox.noAlbumsPresent'/>)
                      }
                      const group = await promptAutocomplete(
                        <Translation name="T.pictureBox.whatAlbumDoYouWantToAdd"/>, 
                        '', 
                        async s => groups.filter(it => it.name.toLowerCase().includes(s.toLowerCase())).map(it => ({
                          label: it.name, value: it.name
                        })), 
                        true,
                        true
                      )
                      if (!group) return
                        
                      const files = (await api.fileManagement.getContents({ path: ['temp', 'albums', group] }, true))
                      await onAddFiles(files.map(file => ({
                        blob: file.contents,
                        name: file.name,
                        extension: file.name.split('.').pop().toLowerCase()
                      })))

                      // archive them in case of recovery requirements
                      await api.fileManagement.moveObject({
                        oldPath: ['temp', 'albums', group],
                        newPath: ['temp', 'archived_albums', group]
                      })
                    }}>
                      <IoIosAlbums/>
                    </ProcessingButton>
                    {mobileView && (
                      <ProcessingButton color='danger' onClick={() => {
                        modalRef.current.toggleClose()
                      }}>
                        <IoCloseCircleOutline/>
                      </ProcessingButton>
                    )}
                  </ButtonGroup>
                </div>

                <div className={classNames('picture-box-inner-view', 'text-center', !image && 'd-none')}>
                  <div className='picture-box-image'>
                    <img alt="to store" src={image} {...sizes} />
                  </div>
                  {!hideCurrentFilesOverview && imageList}
                  <YesNoButtons
                    onNo={() => setImage(null)}
                    onYes={async () => {
                      const base64image = image.split(',')[1];
                      await onAddFiles([{
                        blob: base64image,
                        name: "photo taken at " + moment().format("YYYY-MM-DD HH:mm:ss") + ".png",
                        extension: 'png'
                      }])

                      if (closeAfterOnPicture) {
                        close();
                      } else {
                        setImage(null);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </SimpleModal>
    );
  },
);

export default PictureBox;
