import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  Input,
  InputGroupAddon,
  InputGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { useApi, IApi, ICurrentDeviceConfig, useDeviceConfig } from 'utils/API';
import { useTranslations, TTranslate } from 'utils/language';
import { useNotifications, INotificationContext, addNotification } from 'utils/NotificationManager';
import BufferedExecutor from 'component_utils/bufferedExecutor';
import BarcodeScanner from 'components/barcode_scanner';
import { HooksHoc, MaybePromise, useConstantRef, useRefInitOnce } from 'component_utils/utils';
import { useHistory, useLocation } from 'react-router';
import VirtualKeyboard, { VirtualKeyboardRef } from './VirtualKeyboard';
import { ColorTypes } from 'styling/stylingVars';
import './style.scss';
import classNames from 'classnames';
import AutoComplete, { AutoCompleteFetcher, AutoCompleteRef } from 'components/Autocomplete';
import { confirm } from 'utils/Prompts';
import Translation from 'utils/Language/Translation';
import { getOneTimeInputFromPeripheral } from 'utils/PeripheralManager';
import { debounce } from 'lodash';
import { FaBarcode } from 'react-icons/fa';

export type ScannerInputModes = 'none' | 'text' | 'numeric' | 'date';

export type ScannerDropdownItem = {
  color: ColorTypes;
  onClick: any;
  content: ReactNode;
  id: string;
  disabled?: boolean;
};

interface Props {
  api: IApi;
  T: TTranslate;
  notifications: INotificationContext;
  deviceConfig: ICurrentDeviceConfig;
  history: ReturnType<typeof useHistory>;

  isLoading?: boolean;
  inputStep?: any;
  inputType?: ScannerInputModes;
  placeholder: string;
  disabled?: boolean;
  onEnter?: (s: string, ...args: any[]) => MaybePromise<void>;
  customButtons?: ReactNode;
  customDropDowns?: ScannerDropdownItem[];
  commands?: {
    pattern: RegExp;
    action: (args: RegExpMatchArray) => MaybePromise<any>;
  }[];
  priority?: number;
  expectDeviceIdentification?: boolean;

  fetchAutocompleteOptions?: AutoCompleteFetcher;
}

let currentFocusRequest: NodeJS.Timeout = null
export const handleRequestFocus = (event: any) => {
  const anyParentHasAttribute = (el: HTMLElement, attr: string): boolean => {
    if (el.getAttribute(attr) || el.classList.contains(attr)) return true;
    return (el.parentNode &&
      (el.parentNode as HTMLElement).getAttribute &&
      anyParentHasAttribute(el.parentNode as HTMLElement, attr)) as boolean;
  };

  const anyParentIsHidden = (el: HTMLElement): boolean => {
    if (getComputedStyle(el, null).display === 'none') return true
    return el.parentElement && anyParentIsHidden(el.parentElement)
  };

  const findInput = () => {
    let layers = Array.from(document.querySelectorAll(".app,.modal,.layer")).reverse()
    if (layers.length === 0) return;

    // find the input in this layer
    layers = [layers[0]]
    for (const layer of layers) {
      const scanners = Array.from(layer.querySelectorAll(".scannerInput"))
      for (const scanner of scanners) {
        if (anyParentIsHidden(scanner as HTMLElement)) {
          continue
        }
        return scanner as HTMLInputElement
      }
    }  
  }

  // find desired input element
  const input = findInput()
  if (!input) {
    // if no applicable input could be found then no input should currently be focused at all
    currentFocusRequest && clearTimeout(currentFocusRequest);
    currentFocusRequest = null;
    if (document.activeElement?.classList?.contains("scannerInput")) {
      (document.activeElement as HTMLInputElement)?.blur?.()
    }
    return
  }

  // if this focus request was triggered by an event make sure we should apply focus to a scanner
  if (event) {
    // do not hijack focus from these elements
    if (['INPUT', 'SELECT', 'TEXTAREA'].includes(event.target.tagName.toUpperCase())) {
      return;
    }

    // do not hijack focus from label elements
    if (event.target.closest("label")?.htmlFor) {
      return;
    }


    // verify whether or not the clicked element is in a prevent focus section
    const preventAutoFocus = anyParentHasAttribute(event.target, 'data-prevent-auto-focus')

    // check whether we have a force-auto-focus on the element
    const targetHasForceAutoFocus = event.target.getAttribute('data-force-auto-focus')
    const parentButtonHasAutoFocus = event.target.closest("button")?.getAttribute('data-force-auto-focus')
    const hasForceAutoFocus = targetHasForceAutoFocus || parentButtonHasAutoFocus

    // do not autofocus if clicked inside of a prevent autofocus section
    // unless force auto focus is set
    if (!hasForceAutoFocus && preventAutoFocus) {
      return;
    }
  } else {
    // do not hijack focus if the focus is currently given to an input element type
    if (['INPUT', 'SELECT', 'TEXTAREA'].includes(document.activeElement?.tagName?.toUpperCase?.())) {
      return
    }
  }

  currentFocusRequest && clearTimeout(currentFocusRequest);
  currentFocusRequest = setTimeout(() => {
    if (input !== document.activeElement)
      input?.focus?.();
  }, 1);
};

export const initializeScannerFocusHandler = () => {
  const debouncedHandleRequestFocus = debounce(() => handleRequestFocus(null), 250)
  document.addEventListener('click', handleRequestFocus, false);
  window.addEventListener('resize', debouncedHandleRequestFocus);
  return () => {
    document.removeEventListener('click', handleRequestFocus);
    window.removeEventListener('resize', debouncedHandleRequestFocus);
  };
}

export const ScannerFocusTrigger = () => {
  useEffect(() => {
    setTimeout(() => handleRequestFocus(null), 1);
    return () => {
      setTimeout(() => handleRequestFocus(null), 1);
    }
  }, [])
  return <React.Fragment/>
}

const Scanner: FC<Props> = (_props) => {
  const api = useApi();
  const deviceConfig = useDeviceConfig()
  const props = useConstantRef(_props);
  const location = useLocation();
  const executorRef = useRefInitOnce(() => new BufferedExecutor());
  const inputRef = useRef<HTMLInputElement>();
  const keyboardRef = useRef<VirtualKeyboardRef>();
  const autocompleteRef = useRef<AutoCompleteRef>();

  const [dropdownIsRunning, setDropdownIsRunning] = useState<{ [k: string]: boolean }>({});

  useEffect(() => {
    // attempt to gain focus
    handleRequestFocus(null)
    return () => {
      handleRequestFocus(null)
    }
  }, [inputRef]);

  const processValue = () => {
    const inputValue = inputRef.current.value;

    // first test whether it is a repeat pattern
    executorRef.current.execute(async () => {
      if (inputValue.startsWith('_be_redirect_')) {
        props.current.history.push(inputValue.substring('_be_redirect_'.length));
        return;
      }

      if (inputValue.startsWith('_be_device_identification_') && !props.current.expectDeviceIdentification) {
        const deviceName = inputValue.substring('_be_device_identification_'.length)
        if (await confirm(<Translation name="T.warnings.session.areYouSureYouWantToContinueOnDevice" params={{ deviceName: <b>{deviceName}</b> }}/>)) {
          await api.post(`/api/v1/auth/transfer_token/create/${deviceName}`, {
            path: location.pathname
          })  
        }
        return;
      }

      if (inputValue.startsWith('_be_override_device_settings_')) {
        const settingsString = inputValue.substring('_be_override_device_settings_'.length)
        const settings = JSON.parse(settingsString)
        deviceConfig.saveDeviceConfiguration(old => {
          return Object.assign({}, old, settings)
        })
        addNotification('success', <Translation name="T.misc.successfullyChangedDeviceSettings"/>)
        return;
      }

      if (inputValue.startsWith('_be_logout_')) {
        // TODO: Wait for all requests to finish
        await api.logout();
        return;
      }

      if (inputValue.startsWith('_be_peripheral_input_')) {
        const config = JSON.parse(inputValue.substring('_be_peripheral_input_'.length))
        const v = await getOneTimeInputFromPeripheral(
          config.usage,
          config.driverName,
          config.deviceName,
          config.settings
        )
        if (props.current.onEnter) {
          await props.current.onEnter(v);
        }
        return;
      }

      for (let index = 0; index < (props.current.commands || []).length; index++) {
        const command = props.current.commands[index];
        const args = inputValue.match(command.pattern);
        if (args) {
          await command.action(args);
          return;
        }
      }

      if (props.current.onEnter) {
        await props.current.onEnter(inputValue);
      }
    });

    // clear the input
    autocompleteRef.current.clearSearch();
    inputRef.current.value = '';
  };

  return (
    <div className="beScannerInput mb-2">
      <AutoComplete
        ref={autocompleteRef}
        onSelect={(s) => {
          inputRef.current.value = s;
          processValue();
        }}
        fetchAutocompleteOptions={props.current.fetchAutocompleteOptions}
      >
        {({ ref, onBlur, onKeyDownHandler, onKeyUpHandler }) => (
          <>
            <InputGroup>
              <Input
                autoFocus
                innerRef={(r) => {
                  inputRef.current = r;
                  ref.current = r;
                }}
                placeholder={props.current.placeholder}
                type="text"
                inputMode="none"
                className={classNames('scannerInput', props.current.isLoading && 'loading')}
                data-testid="scannerInput"
                priority={props.current.priority || 0}
                disabled={props.current.disabled}
                autoComplete={'off'}
                onKeyDown={onKeyDownHandler}
                onKeyUp={onKeyUpHandler}
                onBlur={onBlur}
              />

              <InputGroupAddon addonType="append">{props.current.customButtons}</InputGroupAddon>

              {props.current.customDropDowns && props.current.customDropDowns.filter((it) => it).length > 0 && (
                <UncontrolledButtonDropdown addonType="append">
                  <DropdownToggle split color="tertiary" style={{ width: '42px' }} />
                  <DropdownMenu>
                    {props.current.customDropDowns
                      .filter((it) => it)
                      .map((dd) => (
                        <DropdownItem
                          key={dd.id}
                          data-testid={dd.id}
                          className={`dropdown-item-${dd.color}`}
                          onClick={async () => {
                            setDropdownIsRunning((old) => ({ ...old, [dd.id]: true }));
                            try {
                              await dd.onClick();
                            } finally {
                              setDropdownIsRunning((old) => ({ ...old, [dd.id]: false }));
                            }
                          }}
                          disabled={dropdownIsRunning[dd.id] || dd.disabled}
                        >
                          {dd.content}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              )}

              {(props.current.deviceConfig.useEmbeddedBarcodeScanner || props.current.deviceConfig.useVirtualKeyboard) && (
                <InputGroupAddon addonType="append">
                  {props.current.deviceConfig.useEmbeddedBarcodeScanner && (
                    <BarcodeScanner
                      enableOCR={true}
                      shouldAlwaysScan={false}
                      closeOnScan={false}
                      trigger={(disabled, start) => (
                        <Button
                          color="primary"
                          onClick={start}
                          disabled={disabled}
                          data-testid="openCamera"
                        >
                          <FaBarcode/>
                        </Button>
                      )}
                      onScan={(s: string) => {
                        inputRef.current.value = s;
                        processValue();
                      }}
                    />
                  )}
                  {props.current.deviceConfig.useVirtualKeyboard && (
                    <VirtualKeyboard
                      ref={keyboardRef}
                      inputRef={inputRef}
                      inputStep={props.current.inputStep}
                      inputMode={props.current.inputType}
                      onKeyUp={() => {
                        onKeyUpHandler({} as any);
                      }}
                      onEnter={() => {
                        processValue();
                      }}
                    />
                  )}
                </InputGroupAddon>
              )}
            </InputGroup>
          </>
        )}
      </AutoComplete>
    </div>
  );
};

export default HooksHoc(Scanner, () => ({
  api: useApi(),
  T: useTranslations(),
  notifications: useNotifications(),
  history: useHistory(),
  deviceConfig: useDeviceConfig().deviceConfig,
}));
